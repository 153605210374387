import React from 'react';
import PropTypes from 'prop-types';
import Content from './content/Content';
import { Parallax as ReactParallax } from './../../../node_modules/react-parallax';
import styles from './Parallax.module.scss';
import './Parallax.scss';
import classNames from 'classnames';

const Parallax = ({ model, isDesignerMode }) => {
  const { imagePath, imageAlterText } = model.imageSettingSection;
  const { buttonPosition } = model.layoutSettingSection;

  return (
    <>
      {isDesignerMode && <div className={styles.vdMinHeight} />}
      <ReactParallax
        bgImage={imagePath}
        bgImageAlt={imageAlterText}
        strength={400}
        className={classNames(
          styles.parallaxContainer,
          'parallax-content-block',
        )}
      >
        <div className={styles.parallaxWrapper}>
          <Content
            text={model.textSettingSection}
            button={model.buttonSettingSection}
            layoutPosition={buttonPosition}
          />
        </div>
      </ReactParallax>
    </>
  );
};

Parallax.propTypes = {
  model: PropTypes.object.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default React.memo(Parallax);
