import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Content.module.scss';
import Text from '../text/Text';
import { LinkButton } from 'sana/elements';

export const getAlignment = alignment => {
  switch (parseInt(alignment)) {
    case 0:
      return styles.contentLeft;
    case 1:
      return styles.contentRight;
    case 2:
      return '';
    default:
      return '';
  }
};

const Content = ({ text, button, layoutPosition }) => {
  let titleData, subTitleData;

  if (text) {
    titleData = {
      text: text.title,
      color: text.titleColor && text.titleColor,
    };
    subTitleData = {
      text: text.subTitle,
      color: text.subTitleColor && text.subTitleColor,
    };
  }

  let customStyle = {
    color: button.buttonTextColor && button.buttonTextColor,
    backgroundColor: button.buttonBackgroundColor && button.buttonBackgroundColor,
    boxShadow: button.buttonBottomBorderColor && `inset 0 -1px 0 ${button.buttonBottomBorderColor}`,
  };

  const [hover, toggleHover] = useState(false);

  const isTextEmpty = text => {
    return text === '' || text === undefined;
  };

  if (hover) {
    customStyle = {
      ...customStyle,
      backgroundColor: button.buttonBackgroundHoverColor && button.buttonBackgroundHoverColor,
      boxShadow: button.buttonBottomBorderHoverColor && `inset 0 -1px 0 ${button.buttonBottomBorderHoverColor}`,
    };
  }

  return (
    <div className={classNames(styles.content, getAlignment(layoutPosition))}>
      {titleData && <Text {...titleData} />}
      {subTitleData && <Text {...subTitleData} />}
      {
        !isTextEmpty(button.buttonText) &&
        <LinkButton {...button.link}
          style={customStyle}
          onMouseEnter={() => toggleHover(!hover)}
          onMouseLeave={() => toggleHover(!hover)}
          className={classNames('parallax-button-click', styles.contentBtnMargin)}
        >
          {button.buttonText}
        </LinkButton>
      }
    </div>
  );
};

Content.propTypes = {
  button: PropTypes.object.isRequired,
  text: PropTypes.object,
};

export default Content;