exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ParallaxContentBlock__Parallax_parallax-container{height:100%;min-height:inherit}.ParallaxContentBlock__Parallax_parallax-wrapper{display:flex;padding:0 25px;flex:1 1}.ParallaxContentBlock__Parallax_vd-min-height{position:absolute;height:26px;width:100%;background-color:var(--placeholderColor,#f7f7f7);z-index:-1}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ParallaxContentBlock__Parallax_parallax-wrapper:after{content:\"\";min-height:inherit}}", ""]);

// exports
exports.locals = {
	"parallax-container": "ParallaxContentBlock__Parallax_parallax-container",
	"parallaxContainer": "ParallaxContentBlock__Parallax_parallax-container",
	"parallax-wrapper": "ParallaxContentBlock__Parallax_parallax-wrapper",
	"parallaxWrapper": "ParallaxContentBlock__Parallax_parallax-wrapper",
	"vd-min-height": "ParallaxContentBlock__Parallax_vd-min-height",
	"vdMinHeight": "ParallaxContentBlock__Parallax_vd-min-height"
};