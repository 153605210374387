exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ParallaxContentBlock__Content_content{display:flex;flex-direction:column;width:100%;justify-content:center;align-items:center;text-align:center}.ParallaxContentBlock__Content_content-left{align-items:flex-start;text-align:left}.ParallaxContentBlock__Content_content-right{align-items:flex-end;text-align:right}.ParallaxContentBlock__Content_content-btn-margin{margin:16px 0}", ""]);

// exports
exports.locals = {
	"content": "ParallaxContentBlock__Content_content",
	"content-left": "ParallaxContentBlock__Content_content-left",
	"contentLeft": "ParallaxContentBlock__Content_content-left",
	"content-right": "ParallaxContentBlock__Content_content-right",
	"contentRight": "ParallaxContentBlock__Content_content-right",
	"content-btn-margin": "ParallaxContentBlock__Content_content-btn-margin",
	"contentBtnMargin": "ParallaxContentBlock__Content_content-btn-margin"
};